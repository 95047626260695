import React from "react";

import Layout from "../components/Layout.js";

export default () => {
  return (
    <Layout>
      <div>Coming soon...</div>
    </Layout>
  );
};
